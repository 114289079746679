import React, { useState } from 'react';
import home from './img/home.png';
import logo from './img/nav-logo.png';
import './navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const sectionIds = [
    'section0',
    'section3',
    'section1',
    'section5',
    'section4',
    'orderSection'
  ];

  const handleClick = (index) => {
    const links = document.querySelectorAll('.nav-link');
    links.forEach((link, idx) => {
      link.classList.toggle('active', idx === index);
    });
    if (index === 3) {
      window.open('https://mor-vit.com/Privacy%20Policy/', '_blank');
    }
    // else if (index === 4) {
    //   window.open('https://orderkiwi.site/o/31/5wqo2iyhos', '_blank');

    // }
    
    const targetElement = document.getElementById(sectionIds[index]);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar-item">
      <div>
        <img className="logo-mor" src={logo} alt="MOR logo" />
      </div>
      <ul className={`nav-menu ${isMenuOpen ? 'open' : ''}`} style={{ flexDirection: 'row-reverse' }}>
        <li>
          <a href="#/" onClick={() => handleClick(0)}>
            <img className="home-logo" src={home} alt="home" />
          </a>
        </li>
        {[
          'الصفحة الرئيسية',
          'لماذا MOR ؟',
          'فوائده و استخداماته',
          'سياسة الخصوصية',
          'اطلب الآن'
        ].map((text, index) => (
          <li key={index}>
            <a
              className="nav-link"
              href="#/"
              onClick={() => handleClick(index)}
              style={{ width: '150px', height: '50px' }}
            >
              {text}
            </a>
          </li>
        ))}
      </ul>
      <div className="menu" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Navbar;
