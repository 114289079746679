import React, { useState } from 'react';
import Navbar from "./components/Navbar";
import img1 from "./components/img/الصفحة 1.jpg";
import img2 from "./components/img/الصفحة 2.jpg";
import img4 from "./components/img/Page2.jpg";
import img3 from "./components/img/Page3.jpg";
import img5 from "./components/img/صفحة 4 اراء العملاء تكون فوق من صفحة 3.png";

import Standard from "./components/img/Standard.png";
import Single from "./components/img/Single.png";
import Premium from "./components/img/Premium.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Lottie from 'lottie-react';
import animationData from './whatsapp.json';
import "./landingPage.css";

import Form from './components/Form';

const LandingPage = () => {
  const slides = [Single, Standard, Premium];
  const middleSlideIndex = Math.floor(slides.length / 2);

  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');

  const openPopup = (card) => {
    setSelectedCard(card);
    setOpen(true);
  }
  const closePopup = () => {
    setOpen(false);
  }

  const EmbedOrderKiwi = () => {
    return (
        <iframe 
            src="https://orderkiwi.site/o/32/5wqo2iyhos" 
            width="100%" 
            height="800px"  
            style={{ border: 'none' }}
            title="Order Kiwi"
        />
    );
};
  return (
    <div className="landing-page">
      <Navbar />
      <img id="section0" className="img1" alt='img' src={img1} style={{ marginTop: "0" }} />
      {/* <div className="button1" onClick={() => document.getElementById('section7').scrollIntoView({ behavior: "smooth" })}>
        <i className="fas fa-angle-left"></i>اطلب الآن
      </div> */}
      <img id="section1" className="img1" alt='img' src={img2} />
      <img id="section3"className="img1" alt='img' src={img4} />
      {/* <img id="section3" className="img1" src={img4} />
      <img id="section4" className="img1" src={img5} /> */}
      {/* <img id="section5" className="img1" src={img6} /> */}
      <img id="section5" className="img1" src={img3} />
      <img id="section5" className="img1" src={img5} />
      <div id="section4" className="img8-container">
              {/* <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: true
          }}
          loop={true}
          className="swiper-container"
          initialSlide={middleSlideIndex}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div onClick={() => openPopup(`Order ${index + 1}`)} className="box">
                <img className="card" src={slide} alt={`Slide ${index + 1}`} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper> */}
        <EmbedOrderKiwi />
      </div>
      
      {/* <div id="orderSection" className="img7"></div> */}
      <Form open={open} closePopup={closePopup} selectedCard={selectedCard} />
      <div className="whatsapp-container">
        <a href="https://api.whatsapp.com/send/?phone=%2B905078055025&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
          <Lottie animationData={animationData} className="whatsapp" />
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
